<template>
  <!--begin::Modal - New Target-->
  <div class="modal fade" id="modal_print" tabindex="-1" aria-hidden="true">
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-850px">
      <!--begin::Modal content-->
      <div class="modal-content rounded">
        <!--begin::Modal header-->
        <div class="modal-header pb-0 border-0 justify-content-end">
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotone/Navigation/Close.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div class="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">
          <div id="printableArea" class="home" style="width: 1000px !important">
            <!--begin::Row-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-12 fw-bold text-muted">ผู้ข้ออนุญาต ชื่อ</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-12">
                <span class="fw-bolder fs-6 text-dark">{{ data.name }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
            
            <!--begin::Row-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-12 fw-bold text-muted">ประเภท</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-12">
                <span class="fw-bolder fs-6 text-dark">{{ data.user_type }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
            <template v-if="data.user_type === 'นิติบุคคล'">
              <!--begin::Row-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-12 fw-bold text-muted">นิติบุคคลประเภท</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-12">
                  <span class="fw-bolder fs-6 text-dark">{{
                    data.JuristicPersonType
                  }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->
              <!--begin::Row-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-12 fw-bold text-muted">จดทะเบียนเมื่อ</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-12">
                  <span class="fw-bolder fs-6 text-dark">{{
                    dateThai1(RegisteredOn)
                  }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->
              <!--begin::Row-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-12 fw-bold text-muted">เลขทะเบียน</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-12">
                  <span class="fw-bolder fs-6 text-dark">{{
                    data.RegistrationNumber
                  }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->
              <!--begin::Row-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-12 fw-bold text-muted">มีสำนักงานเลขที่</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-12">
                  <span class="fw-bolder fs-6 text-dark"
                    >{{ data.officeNumber }}
                    <span v-if="data.officeMoo"> หมู่ {{ data.officeMoo }} </span>
                    <span v-if="data.officeAlleyway">
                      ตรอก/ซอย {{ data.officeAlleyway }}
                    </span>
                    <span v-if="data.officeRoad"> ถนน {{ data.officeRoad }} </span>
                    {{ data.officeDistrict }} {{ data.officeAmphoe }}
                    {{ data.officeProvince }}
                    {{ data.officeZipcode }}
                  </span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->
              <!--begin::Row-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-12 fw-bold text-muted">เบอร์โทรศัพท์</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-12">
                  <span class="fw-bolder fs-6 text-dark">{{ data.officeTel }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->
              <!--begin::Row-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-12 fw-bold text-muted">โทรสาร</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-12">
                  <span class="fw-bolder fs-6 text-dark">{{ data.officeTels }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->
              <!--begin::Row-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-12 fw-bold text-muted"
                  >ผู้มีอำนาจลงชื่อแทนนิติบุคคลผู้ขออนุญาต</label
                >
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-12">
                  <span class="fw-bolder fs-6 text-dark">{{ data.agent }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->
            </template>

            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-12 fw-bold text-muted">บ้านเลขที่</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-12 fv-row">
                <span class="fw-bold fs-6"
                  >{{ data.numberhome }}
                  <span v-if="data.moo"> หมู่ {{ data.moo }} </span>
                  <span v-if="data.alleyway"> ตรอก/ซอย {{ data.alleyway }} </span>
                  <span v-if="data.road"> ถนน {{ data.road }} </span>
                  {{ data.district }} {{ data.amphoe }} {{ data.province }}
                  {{ data.zipcode }}</span
                >
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-12 fw-bold text-muted">เบอร์โทรศัพท์ </label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-12 d-flex align-items-center">
                <span class="fw-bold fs-6">{{ data.tel }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-12 fw-bold text-muted">ขอยื่นคำขอรับใบอนุญาต</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-12 fv-row">
                <span class="fw-bold fs-6">{{ data.license }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-12 fw-bold text-muted">ที่เลขที่</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-12 fv-row">
                <span class="fw-bold fs-6"
                  >{{ data.RequestNumber }}
                  <span v-if="data.RequestMoo"> หมู่ {{ data.RequestMoo }} </span>
                  <span v-if="data.RequestAlleyway">
                    ตรอก/ซอย {{ data.RequestAlleyway }}
                  </span>
                  <span v-if="data.RequestRoad"> ถนน {{ data.RequestRoad }} </span>
                  ตำบล จองคำ อำเภอเมืองแม่ฮ่องสอน จังหวัดแม่ฮ่องสอน</span
                >
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-12 fw-bold text-muted">ชื่อเจ้าของอาคาร</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-12 fv-row">
                <span class="fw-bold fs-6">{{ data.BuildingOwner }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-12 fw-bold text-muted">ในที่ดิน</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-12 fv-row">
                <span class="fw-bold fs-6">{{ data.land }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-12 fw-bold text-muted">เลขที่</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-12 fv-row">
                <span class="fw-bold fs-6">{{ data.BuildingNumber }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-12 fw-bold text-muted">เป็นที่ดินของ</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-12 fv-row">
                <span class="fw-bold fs-6">{{ data.Landowner }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <template v-for="(item, index) in data.Building" :key="item">
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-12 fw-bold text-muted"
                  >({{ index + 1 }}) ชนิดอาคาร</label
                >
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-12 fv-row">
                  <span class="fw-bold fs-6">{{ item.Buildingtype }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-12 fw-bold text-muted">จำนวนชั้น</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-12 fv-row">
                  <span class="fw-bold fs-6">{{ item.floors }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-12 fw-bold text-muted">จำนวนหลัง</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-12 fv-row">
                  <span class="fw-bold fs-6">{{ item.Buildings }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-12 fw-bold text-muted">เพื่อใช้เป็น</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-12 fv-row">
                  <span class="fw-bold fs-6">{{ item.ToUseAs }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-12 fw-bold text-muted">จำนวนที่จอดรถ</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-12 fv-row">
                  <span class="fw-bold fs-6">{{ item.carNumber }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
            </template>

            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-12 fw-bold text-muted">ผู้ออกแบบและคำนวณ ชื่อ</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-12">
                <span class="fw-bold fs-6">{{ data.nameDesignerCalculate }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-12 fw-bold text-muted">เลขประจําตัวประชาชน</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-12">
                <span class="fw-bold fs-6">{{ data.id_cardDesignerCalculate }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-12 fw-bold text-muted"
                >เลขทะเบียนผู้ประกอบวิชาชีพวิศวกรรมควบคุม เลขที่</label
              >
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-12">
                <span class="fw-bold fs-6">{{
                  data.id_professionalDesignerCalculate
                }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-12 fw-bold text-muted">ผู้ออกแบบ ชื่อ</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-12">
                <span class="fw-bold fs-6">{{ data.nameDesigner }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-12 fw-bold text-muted">เลขประจําตัวประชาชน</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-12">
                <span class="fw-bold fs-6">{{ data.id_cardDesigner }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-12 fw-bold text-muted"
                >เลขทะเบียนผู้ประกอบวิชาชีพสถาปัตยกรรมควบคุม เลขที่</label
              >
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-12">
                <span class="fw-bold fs-6">{{ data.id_professionalDesigner }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-12 fw-bold text-muted">กำหนดแล้วเสร็จสิ้น (วัน)</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-12">
                <span class="fw-bold fs-6">{{ data.TimePeriod }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-12 fw-bold text-muted">วันที่แจ้ง</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-12">
                <span class="fw-bold fs-6">{{ dateThai(data.created_at) }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-12 fw-bold text-muted">สถานะ</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-12">
                <span class="fw-bold fs-6">{{ data.status }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
          </div>

          <!--begin::Actions-->
          <div class="text-end">
            <button type="button" @click="Print" class="btn btn-primary">
              ยืนยัน พิมพ์
            </button>
          </div>
          <!--end::Actions-->

          <!--end::Col-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - New Target-->
</template>

<script>
import { defineComponent, ref } from "vue";
import moment from "moment";
import printJS from "print-js";
export default defineComponent({
  name: "print",
  props: ["data"],
  setup() {
    const show = ref(false);

    function dateThai(date) {
      var y = moment(date).format("yyy");
      var year = parseInt(y) + 543;
      var march = moment(date).locale("th");

      return march.format("วันที่ D MMMM " + year);
    }
    const Print = () => {
      printJS({
        printable: "printableArea",
        type: "html",
        style:
          ".home{width: 1000px !important} .text-center{text-align: center;} .text-start{text-align: left;} .text-end{text-align: right;}",
        font_size: "12pt",
        font: "Sarabun sans-serif",
      });
    };
    return { Print, show, dateThai };
  },
});
</script>

<style></style>
