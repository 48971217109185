<template>
  <div class="vld-parent">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      :color="color"
    ></loading>
    <!--begin::Post-->
    <div class="post d-flex flex-column-fluid" id="kt_post">
      <!--begin::Container-->
      <div id="kt_content_container" class="container">
        <Navbar v-if="data" :profile_line="profile_line" :data="data"></Navbar>
        <Detail v-if="data" :data="data"></Detail>
        <Files v-if="data" :data="data"></Files>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/building_permit/Navbar";
import Detail from "@/components/building_permit/Detail";
import Files from "@/components/building_permit/Files";
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import { checkPage } from "@/core/helpers/checkpage";
import useApi from "@/core/services/api/building_permit";
import useSweetalert from "@/core/helpers/sweetalert2";
export default defineComponent({
  name: "building_permit-show",
  components: {
    Navbar,
    Detail,
    Files
  },
  setup() {
    const { SToast, SconfirmNc } = useSweetalert();
    const route = useRoute();
    const router = useRouter();
    const id = ref(route.params.id);
    const { getData } = useApi();
    const data = ref([]);
    const profile_line = ref([]);
    let isLoading = ref(true);
    let color = ref(process.env.VUE_APP_COLOR);

    onMounted(() => {
      checkPage("building_permit-list");
      setCurrentPageBreadcrumbs("ใบงานที่ " + id.value, ["ขออนุญาตก่อสร้าง"]);
      getApp();
    });

    async function getApp() {
      isLoading.value = true;
      await getData(id.value)
        .then((res) => {
          if (res.data.status === "Not Ticket") {
            SToast("error", "ไม่พบ ใบงานนี้");
            router.push({ name: "apps.building_permit.wait" });
          } else if (res.data.status === "Not Auth") {
            SToast("error", "ไม่มีสิทธิ์เข้าถึง ใบงานนี้");
            router.push({ name: "apps.building_permit.wait" });
          }

          data.value = res.data.data;
          profile_line.value = res.data.profile_line;
          isLoading.value = false;
        })
        .catch(() => {
          SconfirmNc("เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง", "error", "ลองอีกครั้ง !");
          isLoading.value = false;
        });
    }

    return { id, isLoading, color, data, profile_line };
  },
});
</script>
<style scoped></style>
