<template>
  <!--begin::details View-->
  <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
    <!--begin::Card header-->
    <div class="card-header cursor-pointer">
      <!--begin::Card title-->
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">เอกสารแนบ</h3>
      </div>
      <!--end::Card title-->
    </div>
    <!--begin::Card header-->
    <!--begin::Card body-->
    <div class="card-body p-9">
      <!--begin::Row-->
      <div class="row mb-7" v-if="data.file_idcard">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted"
          >สำเนาบัตรประจำตัวประชาชน "ผู้ขออนุญาต, ผู้จัดการนิติบุคคล"</label
        >
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="getFiles(data.file_idcard)"
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Row-->
      <div class="row mb-7" v-if="data.file_house">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted"
          >สำเนาทะเบียนบ้าน "ผู้ขออนุญาต, ผู้จัดการนิติบุคคล"</label
        >
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="getFiles(data.file_house)"
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Row-->
      <div class="row mb-7" v-if="data.file_power_of_attorney">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">หนังสือมอบอำนาจ</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="getFiles(data.file_power_of_attorney)"
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Row-->
      <div class="row mb-7" v-if="data.file_idcard_power_of_attorney">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted"
          >สำเนาบัตรประจำตัวประชาชน ผู้รับมอบอำนาจ</label
        >
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="getFiles(data.file_idcard_power_of_attorney)"
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Row-->
      <div class="row mb-7" v-if="data.file_house_power_of_attorney">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">สำเนาทะเบียนบ้าน ผู้รับมอบอำนาจ</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="getFiles(data.file_house_power_of_attorney)"
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Row-->
      <div class="row mb-7" v-if="data.file_business_registration_certificate">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">หนังสือรับรองนิติบุคคล</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="getFiles(data.file_business_registration_certificate)"
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Row-->
      <div class="row mb-7" v-if="data.file_plan">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted"
          >แผนผังบริเวณ แบบแปลน และรายการประกอบแบบแปลน</label
        >
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="getFiles(data.file_plan)"
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Row-->
      <div class="row mb-7" v-if="data.file_plan_calculated">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">รายการคำนวณ</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="getFiles(data.file_plan_calculated)"
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Row-->
      <div class="row mb-7" v-if="data.file_plan_demolish_building">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">มาตรการรื้อถอนอาคาร</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="getFiles(data.file_plan_demolish_building)"
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Row-->
      <div class="row mb-7" v-if="data.file_designer_certificate_architect_designer">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted"
          >หนังสือรับรองของสถาปนิกผู้ออกแบบอาคาร (น. 2)</label
        >
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="getFiles(data.file_designer_certificate_architect_designer)"
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Row-->
      <div class="row mb-7" v-if="data.file_designer_license_profession_architecture">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted"
          >สำเนาใบอนุญาตเป็นผู้ประกอบวิชาชีพสถาปัตยกรรมควบคุม</label
        >
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="getFiles(data.file_designer_license_profession_architecture)"
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Row-->
      <div class="row mb-7" v-if="data.file_designer_certificate_profession_architecture">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted"
          >หนังสือรับรองการได้รับอนุญาตให้เป็นผู้ประกอบวิชาชีพสถาปัตยกรรมควบคุมที่ออกโดยสภาสถาปนิก</label
        >
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="getFiles(data.file_designer_certificate_profession_architecture)"
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Row-->
      <div class="row mb-7" v-if="data.file_designer_idcard_engineer_designer">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted"
          >หนังสือรับรองของวิศวกรผู้ออกแบบและคํานวณอาคาร (น. 2)</label
        >
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="getFiles(data.file_designer_idcard_engineer_designer)"
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Row-->
      <div class="row mb-7" v-if="data.file_designer_license_profession_engineer">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted"
          >สำเนาใบอนุญาตเป็นผู้ประกอบวิชาชีพวิศวกรรมควบคุม</label
        >
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="getFiles(data.file_designer_license_profession_engineer)"
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Row-->
      <div
        class="row mb-7"
        v-if="data.file_designer_certificate_license_profession_engineer"
      >
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted"
          >หนังสือรับรองการได้รับอนุญาตให้เป็นผู้ประกอบวิชาชีพวิศวกรรมควบคุมที่ออกโดยสภาวิศวกร</label
        >
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="getFiles(data.file_designer_certificate_license_profession_engineer)"
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Row-->
      <div class="row mb-7" v-if="data.file_supervisor_name">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted"
          >หนังสือแจ้งชื่อผู้ควบคุมงานตามมาตา 29 วรรคหนึ่ง (น. 3)</label
        >
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="getFiles(data.file_supervisor_name)"
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Row-->
      <div class="row mb-7" v-if="data.file_supervisor_consent_architect">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted"
          >หนังสือยินยอมของสถาปนิกผู้ควบคุมงานตามมาตรา 29 วรรคหนึ่ง (แบบ น. 4)</label
        >
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="getFiles(data.file_supervisor_consent_architect)"
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Row-->
      <div class="row mb-7" v-if="data.file_supervisor_license_professional_architecture">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted"
          >สำเนาใบอนุญาตเป็นผู้ประกอบวิชาชีพสถาปัตยกรรมควบคุม</label
        >
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="getFiles(data.file_supervisor_license_professional_architecture)"
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Row-->
      <div
        class="row mb-7"
        v-if="data.file_supervisor_certification_professional_architecture"
      >
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted"
          >หนังสือรับรองการได้รับอนุญาตให้เป็นผู้ประกอบวิชาชีพสถาปัตยกรรมควบคุมที่ออกโดยสภาสถาปนิก</label
        >
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="
              getFiles(data.file_supervisor_certification_professional_architecture)
            "
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Row-->
      <div class="row mb-7" v-if="data.file_supervisor_consent_engineer">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted"
          >หนังสือยินยอมของวิศวกรผู้ควบคุมงานตามมาตรา 29 วรรคหนึ่ง (แบบ น. 4)</label
        >
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="getFiles(data.file_supervisor_consent_engineer)"
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Row-->
      <div class="row mb-7" v-if="data.file_supervisor_license_engineer">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted"
          >สำเนาใบอนุญาตเป็นผู้ประกอบวิชาชีพวิศวกรรมควบคุม</label
        >
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="getFiles(data.file_supervisor_license_engineer)"
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Row-->
      <div class="row mb-7" v-if="data.file_supervisor_certification_profession_engineer">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted"
          >หนังสือรับรองการได้รับอนุญาตให้เป็นผู้ประกอบวิชาชีพวิศวกรรมควบคุมที่ออกโดยสภาวิศวกร</label
        >
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="getFiles(data.file_supervisor_certification_profession_engineer)"
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Row-->
      <div class="row mb-7" v-if="data.file_title_deed_other">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted"
          >สำเนา โฉนดที่ดิน น.ส. 3 / น.ส. 3 ก. / ส.ค. 1 หรือ อื่น ๆ</label
        >
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="getFiles(data.file_title_deed_other)"
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->
      <!--begin::Row-->
      <div class="row mb-7" v-if="data.file_consent_land_owner_other">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted"
          >หนังสือยินยอมของเจ้าของที่ดิน ในกรณีที่ก่อสร้างอาคารในที่ดินของผู้อื่น</label
        >
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="getFiles(data.file_consent_land_owner_other)"
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->
      <!--begin::Row-->
      <div class="row mb-7" v-if="data.file_id_card_land_owner_other">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted"
          >สำเนาบัตรประจำตัวประชาชนเจ้าของที่ดิน
          ในกรณีที่ก่อสร้างอาคารในที่ดินของผู้อื่น</label
        >
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="getFiles(data.file_id_card_land_owner_other)"
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->
      <!--begin::Row-->
      <div class="row mb-7" v-if="data.file_idcard_power_of_attorney_other">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted"
          >สำเนาทะเบียนบ้านเจ้าของที่ดิน ในกรณีที่ก่อสร้างอาคารในที่ดินของผู้อื่น</label
        >
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="getFiles(data.file_idcard_power_of_attorney_other)"
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->
      <!--begin::Row-->
      <div class="row mb-7" v-if="data.file_consent_close_land_other">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted"
          >หนังสือยินยอมปลูกสร้างอาคารชิดที่ดิน ในกรณีก่อสร้างอาคารชิดที่ดิน</label
        >
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="getFiles(data.file_consent_close_land_other)"
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->
      <!--begin::Row-->
      <div class="row mb-7" v-if="data.file_id_crad_close_land_other">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted"
          >สำเนาบัตรประจำตัวประชาชนเจ้าของที่ดินข้างเคียง
          ในกรณีที่ก่อสร้างอาคารชิดที่ดิน</label
        >
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="getFiles(data.file_id_crad_close_land_other)"
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Row-->
      <div class="row mb-7" v-if="data.file_house_close_land_other">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted"
          >สำเนาทะเบียนบ้านเจ้าของที่ดินข้างเคียง ในกรณีที่ก่อสร้างอาคารชิดที่ดิน</label
        >
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="getFiles(data.file_house_close_land_other)"
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Row-->
      <div class="row mb-7" v-if="data.file_title_deed_close_land_other">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted"
          >สำเนาโฉนดที่ดิน / นส. ๓ / นส. ๓ ก. / ส.ค. ๑ หรือ อื่น ๆ ของที่ดินข้างเคียง
          ในกรณีก่อสร้างอาคารชิดที่ดิน</label
        >
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="getFiles(data.file_title_deed_close_land_other)"
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Row-->
      <div class="row mb-7" v-if="data.file_demolish_the_building_other">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted"
          >รื้อถอนอาคาร กรณีที่ดินติดจำนองอาคาร
          ให้แนบหนังสือยินยอมให้รื้อถอนอาคารจากธนาคาร</label
        >
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="getFiles(data.file_demolish_the_building_other)"
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <div class="row mb-7" v-if="data.file_other">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">เอกสารอื่น ๆ</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="getFiles(data.file_other)"
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->
    </div>
    <!--end::Card body-->
  </div>
  <!--end::details View-->
</template>

<script>
import moment from "moment";
import { defineComponent } from "vue";
export default defineComponent({
  name: "building_permit-show-detail",
  props: ["data"],
  setup() {
    function dateThai(date) {
      var y = moment(date).format("yyy");
      var year = parseInt(y) + 543;
      var march = moment(date).locale("th");

      return march.format("D MMM" + year + ", LT");
    }

    function dateThai1(date) {
      var y = moment(date).format("yyy");
      var year = parseInt(y) + 543;
      var march = moment(date).locale("th");

      return march.format("D MMM" + year);
    }

    function getFiles(file) {
      window.open("https://api.mhscity.com/storage/upload/building_permits/file/" + file);
    }

    return {
      dateThai,
      dateThai1,
      getFiles,
    };
  },
});
</script>

<style scoped></style>
